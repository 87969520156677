import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";

export function TwoImages() {
  const { t } = useTranslation();

  const [sectionBackgroundColor, setSectionBackgroundColor] = useState("");
  const [textTitle, setTextTitle] = useState("");
  const [imagePath1, setImagePath1] = useState("");
  const [imagePath2, setImagePath2] = useState("");
  const [imageTitle1, setImageTitle1] = useState("");
  const [imageTitle2, setImageTitle2] = useState("");
  const [imageCaption1, setImageCaption1] = useState("");
  const [imageCaption2, setImageCaption2] = useState("");
  const [button1Title, setButton1Title] = useState("");
  const [button2Title, setButton2Title] = useState("");
  const [button1Url, setButton1Url] = useState("");
  const [button2Url, setButton2Url] = useState("");

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const twoImagesPath = process.env.REACT_APP_CMS_TWOIMAGES_PATH!;
    const absPath = cmsUrl + twoImagesPath;

    axios
      .get(absPath)
      .then((res) => {
        const {
          sectionBackgroundColor,
          title,
          image1,
          image2,
          image1Title,
          image2Title,
          image1Caption,
          image2Caption,
          button1Title,
          button2Title,
          button1Url,
          button2Url,
        } = res.data.fields;

        document.documentElement.style.setProperty(
          "--twoImagesSectionBackgroundColor",
          "#" + sectionBackgroundColor
        );
        // setSectionBackgroundColor("#" + res.data.fields.sectionBackgroundColor);
        // document.documentElement.style.setProperty('--twoImagesTextTitle', title);
        setTextTitle(title);

        setImagePath1("/assets/img/frontpage/2blocks_img1.webp");

        setImagePath2("/assets/img/frontpage/2blocks_img2.webp");
        setImageTitle1(image1Title);
        setImageTitle2(image2Title);
        setImageCaption1(image1Caption);
        setImageCaption2(image2Caption);
        setButton1Title(button1Title);
        setButton2Title(button2Title);
        setButton1Url(button1Url);
        setButton2Url(button2Url);
      })
      .catch((err) => {});
  }, []);

  return (
    // <section className="product-benefits" style={{ backgroundColor: sectionBackgroundColor }} >
    <section className="product-benefits two-images-background">
      <Container>
        <div className="col-12">
          <h3>{textTitle}</h3>
          <div className="row">
            <div className="col-12 col-sm-6">
              <span>
                <img src={imagePath1} alt="" />
                <div>
                  <h2 className="display-3">{imageTitle1}</h2>
                  <p className="lead">{imageCaption1}</p>
                  <a href={button1Url} className="btn btn-primary">
                    {button1Title}
                  </a>
                </div>
              </span>
            </div>
            <div className="col-12 col-sm-6">
              <span>
                <img src={imagePath2} alt="" />
                <div>
                  <h2 className="display-3">{imageTitle2}</h2>
                  <p className="lead">{imageCaption2}</p>
                  <a href={button2Url} className="btn btn-primary">
                    {button2Title}
                  </a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
