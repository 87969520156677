import { faFileLines } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody } from "reactstrap";
import { CancellationPolicy } from "../../api/rentMyApi";
import {
  flexibleCancellationDescription,
  moderateCancellationDescription,
  strictCancellationDescription,
} from "../item/ItemListingConstantsAndDefaults";
import BasicModal from "../common/BasicModal";
import ItemSnippet from "../common/ItemSnippet";
import { Typography } from "@mui/material";
import MarkdownModal from "../modal/MarkdownModal";

export default function PoliciesSnippetWithModal({
  cancellationPolicy,
  insurance,
  hideSmallText = false,
}: {
  cancellationPolicy: CancellationPolicy | undefined;
  insurance: boolean | undefined;
  hideSmallText?: boolean;
}) {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState(false);
  const [pdfModalOpen, setPdfModalOpen] = useState(false);

  const getCancellationPolicyColour = (
    cancellationPolicy: CancellationPolicy | undefined
  ): string => {
    if (!cancellationPolicy) return "";

    if (cancellationPolicy === CancellationPolicy.Flexible) {
      return "cancellation-header-1";
    }

    if (cancellationPolicy === CancellationPolicy.Moderate) {
      return "cancellation-header-2";
    }

    if (cancellationPolicy === CancellationPolicy.Strict) {
      return "cancellation-header-3";
    }

    return "";
  };

  const returnCancellationTitle = (
    cancellationPolicy: CancellationPolicy
  ): string => {
    if (cancellationPolicy === CancellationPolicy.Flexible) {
      return t("item_listing_policies_flexible");
    }

    if (cancellationPolicy === CancellationPolicy.Moderate) {
      return t("item_listing_policies_moderate");
    }

    if (cancellationPolicy === CancellationPolicy.Strict) {
      return t("item_listing_policies_strict");
    }

    return "";
  };

  const returnCorrectCancellationBody = (
    cancellationPolicy: CancellationPolicy
  ): JSX.Element => {
    if (cancellationPolicy === CancellationPolicy.Flexible) {
      return flexibleCancellationDescription();
    }

    if (cancellationPolicy === CancellationPolicy.Moderate) {
      return moderateCancellationDescription();
    }

    if (cancellationPolicy === CancellationPolicy.Strict) {
      return strictCancellationDescription();
    }

    return <></>;
  };

  const PolicyRow = ({
    title,
    onClick,
  }: {
    title: string;
    onClick: () => void;
  }) => {
    return (
      <ItemSnippet
        wantInCard={false}
        icon={{ iconElement: <FontAwesomeIcon icon={faFileLines} /> }}
        text={{
          title: title,
          text: cancellationPolicy ? cancellationPolicy : "",
          secondaryText: "",
        }}
        buttonSetup={{
          text: t("view"),
          onClick: onClick,
          buttonOutline: true,
        }}
      />
    );
  };

  return (
    <>
      <Card>
        <CardBody>
          <h2>Policies</h2>
          <PolicyRow
            title={t("item_detailed_rent_me_cancellation_policy")}
            onClick={() => setModalState(!modalState)}
          />
          {insurance && (
            <PolicyRow
              title={t("insurance_docs")}
              onClick={() => setPdfModalOpen(!modalState)}
            />
          )}
          {!hideSmallText && insurance && (
            <>
              <br />
              <Typography variant="subtitle2" fontSize="0.7em">
                RentMy Ltd is an Introducer Appointed Representative of Brett
                Noble which is a trading style of Feast Noble & Company LLP
                Authorised and Regulated by the Financial Conduct Authority FRN
                490071
              </Typography>
            </>
          )}
        </CardBody>
      </Card>

      {cancellationPolicy && (
        <BasicModal
          headerClass={getCancellationPolicyColour(cancellationPolicy)}
          modalTitle={returnCancellationTitle(cancellationPolicy)}
          modalBody={returnCorrectCancellationBody(cancellationPolicy)}
          modalState={modalState}
          toggleModal={() => setModalState(!modalState)}
        />
      )}
      <MarkdownModal
        modalState={pdfModalOpen}
        toggleModal={() => setPdfModalOpen(!pdfModalOpen)}
        headerClass={getCancellationPolicyColour(cancellationPolicy)}
        markdownFile="/assets/documents/insurance/policy.markdown"
      />
    </>
  );
}
