import React, { useEffect, useState } from "react";
import { Button, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown as faArrowDownAZ,
  faArrowUp as faArrowUpAZ,
  faPenToSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { IConfig, ItemClient, ItemDto, PricePeriod } from "../../api/rentMyApi";
import { getStandardPricePeriodPriceWithServiceFeeApplied } from "../../utils/pricePeriodUtils";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import StarRating from "../common/StarRating";

interface AccountItemListProps {
  items: ItemDto[] | undefined;
  title: string;
  dataLoaded: boolean;
  sort: string; // Add this line
  setSort: (sort: string) => void; // Add this line
}

const AccountItemList: React.FC<AccountItemListProps> = ({
  items,
  title,
  dataLoaded,
  sort,
  setSort,
}) => {
  const [itemClient, setItemClient] = useState<ItemClient>();

  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      const itemClient = new ItemClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setItemClient(itemClient);
    }

    initClient();
  }, [getAccessTokenSilently, apiServerUrl]);

  const handleListingClick = (id: string) => {
    navigate("/item/" + id);
  };

  const handleEditClick = (id: string) => {
    navigate("/item-listing?id=" + id);
  };

  return (
    <>
      <Card className="custom-card">
        <div className="account-card-header no-margin">
          <h2 className="no-margin">{title}</h2>
          <Button
            color="primary"
            type="button"
            className="account-listing-button profile-section-button"
            outline
            onClick={() => {
              const nextSort =
                sort === "default"
                  ? "desc"
                  : sort === "desc"
                  ? "asc"
                  : "default";
              setSort(nextSort);
            }}
          >
            {sort !== "default" && (
              <FontAwesomeIcon
                icon={sort === "desc" ? faArrowDownAZ : faArrowUpAZ}
                className="account-listing-icon"
              />
            )}
            {t("sort")}
          </Button>
        </div>
        <hr className="mt-3 mb-3 no-top-margin" />
        {!dataLoaded && <div className="loading-spinner-small"></div>}
        {dataLoaded && items && items.length > 0 && (
          <div className="account-booking-list">
            {items?.map((item: ItemDto, index: number) => {
              const {
                name,
                overallAverage,
                reviewCount,
                images = [],
                location,
              } = item;

              const image = images[0];
              const src = image?.compressedPath
                ? image.compressedPath
                : "/assets/img/placeholder.jpg";

              const dailyStandardPricePeriod: PricePeriod | undefined =
                getStandardPricePeriodPriceWithServiceFeeApplied(
                  item!.priceInfo!.periods!,
                  item.name ?? ""
                );

              return (
                <div key={index} className="booking-item uploaded-row">
                  <div
                    className="uploaded-row-image-div cursor-pointer hover-effect"
                    onClick={() => handleListingClick(item!.id)}
                  >
                    <img
                      src={src}
                      alt="Item"
                      className="object-fit-cover uploaded-row-image"
                    />
                  </div>
                  <div
                    onClick={() => handleListingClick(item!.id)}
                    className="cursor-pointer hover-effect"
                  >
                    <p className="uploaded-row-title">{name}</p>
                    <p className="uploaded-row-subtitle">
                      &pound;{dailyStandardPricePeriod?.price.daily} per day,{" "}
                      {location?.city}
                      <br />
                      {reviewCount !== 0 ? (
                        <StarRating
                          overallAverage={overallAverage!}
                          reviewCount={reviewCount!}
                          relativePositioning={false}
                        />
                      ) : (
                        "No reviews yet"
                      )}
                    </p>
                  </div>
                  <div className="booking-status_block option-block">
                    <div className="status-block"></div>
                    {/*<FontAwesomeIcon className="cursor-pointer" size="lg" onClick={() => handleListingClick(item!.id)} color="#E83E33" icon={faEye}/>*/}
                    <span
                      onClick={() => handleEditClick(item!.id)}
                      className="rent-my-red font-weight-600 cursor-pointer"
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        size="lg"
                        color="#E83E33"
                        icon={faPenToSquare}
                      />
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {dataLoaded && items && items.length === 0 && (
          <div className="account-booking-list">{`You do not currently have any ${title.toLowerCase()}`}</div>
        )}
      </Card>
    </>
  );
};

export default AccountItemList;
