import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./MarkdownModal.module.css";

interface CustomImageProps {
  src?: string;
  alt?: string;
  style?: React.CSSProperties;
}

const CustomImage: any = ({ src, alt }: { src: string; alt: string }) => {
  const imagePath = src ? `/assets/documents/insurance/${src}` : "";
  return <img src={imagePath} alt={alt} />;
};

export default function MarkdownModal({
  modalState,
  toggleModal,
  headerClass = "",
  className = "",
  markdownFile = "",
}: {
  modalState: boolean;
  toggleModal: any;
  headerClass?: string;
  className?: string;
  markdownFile?: string;
}) {
  const [fontSize, setFontSize] = useState("16px");
  const [markdownContent, setMarkdownContent] = useState("");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setFontSize("14px");
      } else {
        setFontSize("25px");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchMarkdownContent = async () => {
      try {
        const response = await fetch(markdownFile);
        const content = await response.text();
        setMarkdownContent(content);
      } catch (error) {
        console.error("Error fetching Markdown content:", error);
      }
    };

    if (markdownFile) {
      fetchMarkdownContent();
    }
  }, [markdownFile]);

  const _headerClass = "modal-header background-colour-off-white" + headerClass;
  const headerText = headerClass
    ? "modal-title basic-modal-header text-white"
    : "modal-title basic-modal-header";

  return (
    <>
      <Modal
        className={`markdown-modal modal-dialog-centered ${className}`}
        isOpen={modalState}
        toggle={toggleModal}
      >
        <div className={_headerClass}>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <FontAwesomeIcon className="right-align" icon={faXmark} />
          </button>
        </div>
        <div className={`modal-body ${styles.modalBody}`}>
          <div className={styles.markdownContentWrapper}>
            <ReactMarkdown
              remarkPlugins={[remarkGfm]}
              rehypePlugins={[rehypeRaw]}
              components={{
                img: CustomImage,
              }}
            >
              {markdownContent}
            </ReactMarkdown>
          </div>
        </div>
      </Modal>
    </>
  );
}
