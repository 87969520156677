import {useState, useEffect, Dispatch, SetStateAction, FormEvent} from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import { toast } from 'react-toastify';
import dayjs, { Dayjs } from 'dayjs';
import {
  IConfig,
  ImageDto,
  IRentMyUserDetailedDto,
  RentMyUserDetailedDto,
  UserClient,
  UserIntention,
  TermsSimpleDto,
  TermsClient,
  TermsSimpleDtoPaginationDto, GenderClient,
} from '../../api/rentMyApi';
import { useUserContext } from '../../context/UserContext';
import {
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  TextField,
  Typography,
  Box,
  SxProps,
  Theme,
  FormControlLabel,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SelectChangeEvent } from '@mui/material/Select';
import { MuiTelInput } from 'mui-tel-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { DragAndDropInput } from '../common/DragAndDropInput';

type PersonalInformationStepProps = {
  handleNextStep: () => void;
  headingStyle: SxProps<Theme>;
  inputSelectStyle: SxProps<Theme>;
  inputStyle: SxProps<Theme>;
  errorTextStyle: SxProps<Theme>;
  setIsSharer: Dispatch<SetStateAction<boolean>>;
  showSharerOnboarding: boolean | undefined;
};

interface FormValues {
  firstName: string;
  lastName: string;
  gender: { name: string; label: string };
  phoneNumber: string;
  userIntention: string;
  dateOfBirth: Dayjs | null;
  hasAcceptedTerms: boolean;
}

const initialFormState: FormValues = {
  firstName: '',
  lastName: '',
  gender: { name: '', label: '' },
  phoneNumber: '',
  dateOfBirth: null,
  userIntention: '',
  hasAcceptedTerms: false,
};

interface FormErrors {
  firstName: string;
  lastName: string;
  gender: string;
  phoneNumber: string;
  userIntention: string;
  dateOfBirth: string;
  hasAcceptedTerms: string;
}

const initialFormErrors: FormErrors = {
  firstName: '',
  lastName: '',
  gender: '',
  phoneNumber: '',
  userIntention: '',
  dateOfBirth: '',
  hasAcceptedTerms: '',
};

export function PersonalInformationStep({
  handleNextStep,
  headingStyle,
  inputSelectStyle,
  inputStyle,
  errorTextStyle,
  setIsSharer,
  showSharerOnboarding
}: PersonalInformationStepProps) {
  const { t } = useTranslation();
  const { getAccessTokenSilently } = useAuth0();
  const { user, updateUser, updateAcceptedTerms, hasAcceptedTerms } = useUserContext();
  const [userData, setUserData] = useState<IRentMyUserDetailedDto>(user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialFormState);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);
  const [termsDTO, setTermsDTO] = useState<TermsSimpleDto>();
  const [termsClient, setTermsClient] = useState<TermsClient>();
  const [genderClient, setGenderClient] = useState<GenderClient>();
  const [areClientsInitialisedWithAuth, setAreClientsInitialisedWithAuth] = useState<boolean>();
  const [genderOptions, setGenderOptions] = useState<{ name: string; label: string }[]>([]);

  useEffect(() => {
    async function initClient() {
      const token = await getAccessTokenSilently();
      const termsClient = new TermsClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      const genderClient = new GenderClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);
      setGenderClient(genderClient);
      setTermsClient(termsClient);
      setAreClientsInitialisedWithAuth(true);
    }

    initClient();
  }, []);

  useEffect(() => {
    if (!areClientsInitialisedWithAuth) {
      return;
    }
    populateTermsPage();
  }, [areClientsInitialisedWithAuth]);

  useEffect(() => {
    const getTerms = async () => {
      const options = await genderClient?.gender2(undefined, 10, undefined, undefined, undefined, undefined)
      if (!options || !options.data) {
        toast.error('Something went wrong getting genders');
      }
      else {
        setGenderOptions(options.data.map((option) => ({ name: option.name ?? '', label: option.name ?? '' })));
      }
    }
    if (areClientsInitialisedWithAuth) {
      getTerms();
    }
  }, [genderClient]);

  useEffect(() => {
    if (user.firstName) {
      setFormValues((previousValues) => ({ ...previousValues, firstName: user.firstName ?? '' }));
    }
    if (user.lastName) {
      setFormValues((previousValues) => ({ ...previousValues, lastName: user.lastName ?? '' }));
    }
    if (user.gender) {
      const selectedOption = genderOptions.find((option) => option.name === user.gender);
      if (selectedOption) {
        setFormValues((previousValues) => ({ ...previousValues, gender: selectedOption }));
      }
    }
    if (user.phoneNumber) {
      setFormValues((previousValues) => ({ ...previousValues, phoneNumber: user.phoneNumber ?? '' }));
    }
    if (user.intention) {
      setFormValues((previousValues) => ({
        ...previousValues,
        userIntention: (user.intention as UserIntention) ?? '',
      }));
    }
    if (hasAcceptedTerms) {
      setFormValues((previousValues) => ({ ...previousValues, hasAcceptedTerms: true }));
    }
  }, [user, hasAcceptedTerms, genderOptions]);

  const populateTermsPage = () => {
    termsClient!
      .terms2(undefined, undefined, true, 'Service')
      .then((result: TermsSimpleDtoPaginationDto) => {
        if (!result.data) {
          toast.error('Something went wrong when getting the terms, please contact an admin');
          return;
        }

        const termsOfServiceTermDto = result.data.find((x) => x.termType === 'Service');

        if (!termsOfServiceTermDto) {
          toast.error('Could not find a term. Please contact admin');
          return;
        }

        setTermsDTO(termsOfServiceTermDto);
      })
      .catch((error) => {
        toast.error('Could not load the terms and conditions, please contact an admin');
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormValues((previousValues) => ({ ...previousValues, [e.target.name]: e.target.value }));
  };

  const handleGenderChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = genderOptions.find((option) => option.name === event.target.value);
    if (selectedOption) {
      setFormValues((previousValues) => ({ ...previousValues, gender: selectedOption }));
    }
  };

  const handleUserIntentionChange = (event: SelectChangeEvent<string>) => {
    const selectedOption = ['Both', 'Renter', 'Sharer'].find((option) => option === event.target.value);
    if (selectedOption) {
      setFormValues((previousValues) => ({ ...previousValues, userIntention: selectedOption }));
    }
    if (selectedOption === UserIntention.Renter && !showSharerOnboarding) {
      setIsSharer(false);
    } else setIsSharer(true);
  };

  const handleAcceptTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues((previousValues) => ({ ...previousValues, hasAcceptedTerms: event.target.checked }));
  };

  const validateInput = () => {
    const errors = {
      ...initialFormErrors,
    };
    const { firstName, lastName, gender, phoneNumber, userIntention, hasAcceptedTerms, dateOfBirth } = formValues;

    if (!firstName) {
      errors.firstName = 'Required field';
    }
    if (!lastName) {
      errors.lastName = 'Required field';
    }
    if (!gender.name) {
      errors.gender = 'Required field';
    }
    if (!dateOfBirth) {
      errors.dateOfBirth = 'Required field';
    }
    if (!phoneNumber) {
      errors.phoneNumber = 'Required field';
    } else {
      const countryCode = phoneNumber.substring(0, 3);
      const numberWithoutSpaces = phoneNumber.replace(/\s/g, '');
      if (countryCode === '+44' && numberWithoutSpaces.length !== 13) {
        errors.phoneNumber = 'Invalid phone number';
      } else if (countryCode !== '+44' && numberWithoutSpaces.length > 13) {
        errors.phoneNumber = 'Invalid phone number';
      }
    }
    if (!userIntention) {
      errors.userIntention = 'Required field';
    }

    // check if the user is 18 or older using the date of birth and say "You must be 18 or older to use RentMy" if they are not
    const today = new Date();
    const birthDate = new Date(dateOfBirth!.toDate());
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    if (age < 18) {
      errors.dateOfBirth = 'You must be 18 or older to use RentMy';
    }
    // if (!hasAcceptedTerms) {
    //   errors.hasAcceptedTerms = 'Please accept terms to continue';
    // }
    return errors;
  };

  const acceptTermsAndConditions = async () => {
    const termId = termsDTO!.id;
    try {
      await termsClient?.accept(termId);
    } catch (error) {
      toast.error('Something went wrong when accepting the terms, please contact an admin');
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    const errors = validateInput();
    setFormErrors(errors);
    if (Object.values(errors).find((fieldError) => fieldError)) {
      setIsSubmitting(false);
    } else {
      const token = await getAccessTokenSilently();
      try {
        await acceptTermsAndConditions();
        updateAcceptedTerms(true);
        const response = await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).update25(undefined, {
          firstName: formValues.firstName,
          lastName: formValues.lastName,
          gender: formValues.gender.name,
          phoneNumber: formValues.phoneNumber,
          intention: formValues.userIntention,
          birthDate: formValues.dateOfBirth!.toISOString()
        });
        setUserData(response.data);
        updateUser(response.data);
        setIsSubmitting(false);
        handleNextStep();
      } catch (error) {
        setIsSubmitting(false);
        toast.error(t('account_settings_err_something_goes_wrong'));
      }
    }
  };

  // DEVNOTE - For "handleProfilePhotoUpdateAsync" the backend does not expect you to pass down a userId, this is only if an admin is changing your picture. userId is gotten from the authorisation instead to tell who is the logged in person.
  async function handleProfilePhotoUpdateAsync(imageUpdateData: ImageDto, token: string) {
    try {
      const userContextValueClone = { ...user } as RentMyUserDetailedDto;
      await new UserClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT).changeProfileImage(imageUpdateData.id);
      setUserData({ ...userData, profileImage: imageUpdateData as ImageDto });
      userContextValueClone.profileImage = imageUpdateData as ImageDto;
      toast.success(t('my_profile_img_upload_success'));

      updateUser(userContextValueClone);
    } catch (error) {
      toast.error(t('my_profile_assign_error'));
    }
  }

  const handleDateChange = (date: Dayjs | null) => {
    setFormValues((previousValues) => ({ ...previousValues, dateOfBirth: date }));
  };

  return (
    <Box>
      <Typography component="h1" sx={headingStyle}>
        {t('personal_information')}
      </Typography>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box mb="15px">
            <DragAndDropInput
              label={`${t('profile_picture')}`}
              uploadedImagePath={user?.profileImage?.compressedPath ?? ''}
              handleUpload={handleProfilePhotoUpdateAsync}
            />
            <Typography variant="body1" sx={{ pt: 1 }}>
              {t('profile_picture_description')}
            </Typography>
          </Box>
          <InputLabel id="firstName">{t('manage_profile_about_first_name')}</InputLabel>
          <TextField
            id="firstName"
            name="firstName"
            sx={inputStyle}
            value={formValues.firstName}
            onChange={handleChange}
            error={!!formErrors.firstName}
            helperText={formErrors.firstName}
          />
          <InputLabel id="lastName">{t('manage_profile_about_last_name')}</InputLabel>
          <TextField
            id="lastName"
            name="lastName"
            sx={inputStyle}
            value={formValues.lastName}
            onChange={handleChange}
            error={!!formErrors.lastName}
            helperText={formErrors.lastName}
          />
          <InputLabel id="phoneNumber">{t('phone_number')}</InputLabel>
          <MuiTelInput
            defaultCountry="GB"
            id="phoneNumber"
            name="phoneNumber"
            sx={inputStyle}
            value={formValues.phoneNumber}
            onChange={(value: string) => setFormValues((previousValues) => ({ ...previousValues, phoneNumber: value }))}
            error={!!formErrors.phoneNumber}
            helperText={formErrors.phoneNumber}
          />
          <InputLabel id="gender">{t('gender')}</InputLabel>
          <Select
            sx={inputSelectStyle}
            labelId="gender"
            id="select-gender"
            className="input-field"
            value={formValues.gender.name}
            onChange={(e) => handleGenderChange(e)}
            displayEmpty
            renderValue={(selected: string) => {
              if (!selected) {
                return <em>{t('select_gender')}</em>;
              }
              return formValues.gender.label;
            }}
            error={!!formErrors.gender}
          >
            {genderOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {formErrors.gender && <Typography sx={errorTextStyle}>{formErrors.gender}</Typography>}

          {/*input label for date of both followed by a <DatePicker /> component from MUI*/}
          <InputLabel id="dateOfBirth">{t('date_of_birth')}</InputLabel>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={formValues.dateOfBirth}
              onChange={handleDateChange}
              format="DD/MM/YYYY"
              slotProps={{
                textField: {
                  helperText: formErrors.dateOfBirth,
                  error: !!formErrors.dateOfBirth,
                  sx: inputStyle,
                },
              }}
            />
          </LocalizationProvider>
          {formErrors.dateOfBirth && <Typography sx={errorTextStyle}>{formErrors.dateOfBirth}</Typography>}

          <InputLabel id="userIntention">{t('account_type')}</InputLabel>
          <Select
            sx={inputSelectStyle}
            labelId="userIntention"
            id="select-userIntention"
            className="input-field"
            value={formValues.userIntention}
            onChange={(e) => handleUserIntentionChange(e)}
            displayEmpty
            renderValue={(selected: string) => {
              if (!selected) {
                return <em>Please select an option</em>;
              }
              return formValues.userIntention;
            }}
            error={!!formErrors.userIntention}
          >
            <MenuItem value={UserIntention.Renter}>{t('intention_renter')}</MenuItem>
            <MenuItem value={UserIntention.Sharer}>{t('intention_sharer')}</MenuItem>
            <MenuItem value={UserIntention.Both}>{t('intention_both')}</MenuItem>
          </Select>
          {formErrors.userIntention && <Typography sx={errorTextStyle}>{formErrors.userIntention}</Typography>}
          {/*<FormControlLabel*/}
          {/*  sx={{ marginBottom: 0 }}*/}
          {/*  control={*/}
          {/*    <Checkbox*/}
          {/*      checked={formValues.hasAcceptedTerms}*/}
          {/*      onChange={handleAcceptTermsChange}*/}
          {/*      inputProps={{ 'aria-label': 'controlled' }}*/}
          {/*      size="small"*/}
          {/*    />*/}
          {/*  }*/}
          {/*  label={*/}
          {/*    <Typography variant="body2">*/}
          {/*      {t('terms_of_service_accept_text')}{' '}*/}
          {/*      <a href="/terms" target="_blank">*/}
          {/*        {t('terms_of_service')}*/}
          {/*      </a>*/}
          {/*    </Typography>*/}
          {/*  }*/}
          {/*/>*/}
          {formErrors.hasAcceptedTerms && <Typography sx={errorTextStyle}>{formErrors.hasAcceptedTerms}</Typography>}
          <button
                       type="submit"
                       disabled={isSubmitting || !areClientsInitialisedWithAuth}
                       className="btn btn-primary onboarding-button"
                     >
                       {isSubmitting && (
                         <span className="btn-inner--icon">
                           <FontAwesomeIcon icon={faSpinner} spin />
                         </span>
                       )}
                       {t('onboarding_next')}
                     </button>
                   </Box>
                 </form>
               </Box>
             );
           }