import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";
import { useNavigate } from "react-router-dom";

export function OneWideImage() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [sectionBackgroundColor, setSectionBackgroundColor] = useState("");
  const [textTitle, setTextTitle] = useState("");
  const [imagePath1, setImagePath1] = useState("");
  const [imageTitle1, setImageTitle1] = useState("");
  const [imageCaption1, setImageCaption1] = useState("");
  const [buttonTitle, setButtonTitle] = useState("");
  const [buttonUrl, setButtonUrl] = useState("");

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const oneWideImagePath = process.env.REACT_APP_CMS_ONEWIDEIMAGE_PATH!;
    const absPath = cmsUrl + oneWideImagePath;

    axios
      .get(absPath)
      .then((res) => {
        const { sectionBackgroundColor } = res.data.fields;

        document.documentElement.style.setProperty(
          "--oneWideImageSectionBackgroundColor",
          "#" + sectionBackgroundColor
        );
        setTextTitle(res.data.fields.title);
        setImagePath1("/assets/img/frontpage/1wide_img1.webp");
        setImageTitle1(res.data.fields.image1Title);
        setImageCaption1(res.data.fields.image1Caption);
        setButtonTitle(res.data.fields.buttonTitle);
        setButtonUrl(res.data.fields.buttonUrl);
      })
      .catch((err) => {});
  }, []);

  return (
    <section className="product-benefits one-wide-image-background">
      <Container>
        <div className="col-12">
          <h3>{textTitle}</h3>
          <div className="row">
            <div className="col-12">
              <span>
                <img src={imagePath1} alt="" />
                <div>
                  <h2 className="display-3">{imageTitle1}</h2>
                  <p className="lead">{imageCaption1}</p>
                  <a className="btn btn-primary" href={buttonUrl}>
                    {buttonTitle}
                  </a>
                </div>
              </span>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}