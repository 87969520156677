import { Modal } from "reactstrap";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

interface ImageCarouselModalProps {
  imageSrcs: string[];
  toggleModal: () => void;
  modalState: boolean;
}

export default function ImageCarouselModal(props: ImageCarouselModalProps) {
  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={props.modalState}
        toggle={props.toggleModal}
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={props.toggleModal}
          >
            <FontAwesomeIcon className="right-align" icon={faXmark} />
          </button>
        </div>
        <div className="modal-body">
          <Carousel renderItem={renderZoomImage} showThumbs={false}>
            {props.imageSrcs.map((imageSrc, counter) => {
              return (
                <img
                  key={imageSrc + "_" + counter}
                  className="carousel-image"
                  src={imageSrc}
                  alt={imageSrc}
                />
              );
            })}
          </Carousel>
        </div>
      </Modal>
    </>
  );

  function renderZoomImage(item: React.ReactNode) {
    return <Zoom zoomMargin={20}>{item}</Zoom>;
  }
}
