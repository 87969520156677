import { useRef, useState } from "react";
import { useEnv } from "../../context/env.context";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { FileClient, IConfig, ImageDto } from "../../api/rentMyApi";
import { Box, Typography, Divider } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

type DragAndDropInputProps = {
  label: string;
  uploadedImagePath: string;
  handleUpload: (res: ImageDto, token: string) => void;
};

const approvedFileTypes = ["image/jpg", "image/jpeg", "image/png, image/webp"];

function isApprovedFileType(userFileType: string): boolean {
  return approvedFileTypes.some(
    (approvedFileType) => userFileType === approvedFileType.replace(/\s/g, "")
  );
}

export function DragAndDropInput({
  label,
  uploadedImagePath,
  handleUpload,
}: DragAndDropInputProps) {
  const { t } = useTranslation();
  const { apiServerUrl } = useEnv();
  const { getAccessTokenSilently } = useAuth0();
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [showDropzone, setShowDropzone] = useState<boolean>(false);
  const [dragOver, setDragOver] = useState(false);
  const fileUpload = useRef() as React.MutableRefObject<HTMLInputElement>;

  const onDragOver = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDragOver(true);
  };

  const onDragLeave = () => setDragOver(false);

  const onDrop = async (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e.dataTransfer.files[0];
    await handleUserPhotoUpload(selectedFile);
  };

  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const selectedFile = e.target.files[0];
    await handleUserPhotoUpload(selectedFile);
  };

  const handleUserPhotoUpload = async (selectedFile: File) => {
    setIsImageUploading(true);

    const token = await getAccessTokenSilently();

    const updated_image: File = new File([selectedFile], selectedFile.name);

    if (!isApprovedFileType(selectedFile.type)) {
      toast.error(t("image_upload_file_type_error"));
      setIsImageUploading(false);
      return;
    }

    const apiFileClient = new FileClient(new IConfig(token), process.env.REACT_APP_API_ENDPOINT);

    apiFileClient
      .upload(updated_image.type, {
        fileName: updated_image.name,
        data: updated_image,
      })
      .then(async (res) => {
        await handleUpload(res, token);
      })
      .catch((e) => {
        toast.error(t("my_profile_upload_error"));
      })
      .finally(() => {
        setIsImageUploading(false);
        setShowDropzone(false);
      });
  };

  return (
    <Box>
      <Typography mb="8px" sx={{ color: "rgba(0, 0, 0, 0.6)" }}>
        {label}
      </Typography>
      {/* Show existing image */}
      {uploadedImagePath && !showDropzone && (
        <Box
          width="100%"
          padding="10px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #DEE2E6",
            borderRadius: "4px",
            gap: "10px",
          }}
        >
          <img
            src={uploadedImagePath}
            alt="Profile"
            className="profile-image"
            style={{ height: "150px" }}
          />
          <button
            onClick={() => setShowDropzone(true)}
            className="btn btn-outline-primary btn-secondary"
          >
            {t("update_picture")}
          </button>
        </Box>
      )}
      {/* Show dropzone */}
      {(!uploadedImagePath || showDropzone) && (
        <label
          htmlFor="file"
          onDrop={onDrop}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          style={{
            width: "100%",
            backgroundColor: "#e1e0e0",
            padding: "15px",
            borderRadius: "10px",
          }}
        >
          {dragOver ? (
            <Box
              sx={{
                height: "181px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontSize: "1.8rem" }}>
                {t("drop_here")}
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "15px",
              }}
            >
              <FontAwesomeIcon
                style={{
                  fontSize: "45px",
                  opacity: isImageUploading ? "60%" : "100%",
                }}
                icon={faCloudArrowUp}
                color="#EA554A"
              />
              <Typography sx={{ color: isImageUploading ? "grey" : "black" }}>
                {t("drag_and_drop_files")}
              </Typography>
              <Divider
                aria-hidden="true"
                sx={{
                  width: "100px",
                  color: isImageUploading ? "grey" : "black",
                  "&::before, &::after": {
                    borderColor: isImageUploading ? "grey" : "red",
                  },
                }}
              >
                {t("or")}
              </Divider>
              <button
                className="btn btn-primary"
                type="button"
                disabled={isImageUploading}
                onClick={() => {
                  fileUpload?.current?.click();
                }}
              >
                {isImageUploading && (
                  <span className="btn-inner--icon">
                    <FontAwesomeIcon icon={faSpinner} spin/>
                  </span>
                )}
                <span className="btn-inner--text upload-image-button">
                  {t("browse")}
                </span>
              </button>

              <input
                type="file"
                ref={fileUpload}
                defaultValue=""
                name="file"
                onChange={(e) => onFileSelect(e)}
                accept="image/png, image/jpeg, image/jpg, image/webp"
                className="margin-bottom-20 input-field"
                hidden
              />
            </Box>
          )}
        </label>
      )}
    </Box>
  );
}
