import * as React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import { Container } from "reactstrap";


export function LogoStrip() {
  const emptyStringList: string[] = [];
  const [textTitle, setTextTitle] = useState("");
  const [imagePaths, setImagePaths] = useState(emptyStringList);

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const logoStripPath = process.env.REACT_APP_CMS_LOGOSTRIP_PATH!;
    const absPath = cmsUrl + logoStripPath;

    axios.get(absPath)
      .then(res => {

        const { sectionBackgroundColor, titleTextColor, bodyTextColor } = res.data.fields;

        // setSectionBackgroundColor("#" + res.data.fields.sectionBackgroundColor);
        document.documentElement.style.setProperty("--logo-strip-section-background-color", "#" + sectionBackgroundColor);
        // document.documentElement.style.setProperty('--logoStripTitleTextColor',"#" + titleTextColor);
        // document.documentElement.style.setProperty('--logoStripBodyTextColor',"#" + bodyTextColor);

        // setTitleTextColor("#" + res.data.fields.titleTextColor);
        // setBodyTextColor("#" + res.data.fields.bodyTextColor);

        const _imagePaths: string[] = [];

        setTextTitle(res.data.fields.title);

        const imagePathKeys = Object.keys(res.data.fields).filter(key => key.startsWith("image"));

        for (const imagePathKey of imagePathKeys) {
          const url = cmsUrl + res.data.fields[imagePathKey].fields.umbracoFile.src;
          _imagePaths.push(url);
        }

        setImagePaths(_imagePaths);
      })
      .catch(err => {
      });

  }, []);


  const routeChange = url => {
    // console.log(url);
    if (url !== "") {
      window.location.replace(url);
    }
  };


  return (
    <section className="logo-strip">
      <Container>
        <div className="col-12">
          <h3>{textTitle}</h3>
          <div className="logo-cards">

            {imagePaths.map((imagePath, index) => <div key={"logoStrip-" + index} className="logo-card">
              <img src={imagePath} alt="" />
            </div>)}
          </div>
        </div>
      </Container>
    </section>
  );
}
