import React, { useState, useRef } from "react";
import { Button, Col, Row, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTag, faX } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUserContext } from "../../context/UserContext";

type PromoCodeInputProps = {
  appliedVouchers: string[];
  setAppliedVouchers: React.Dispatch<React.SetStateAction<string[]>>;
  totalPrice: number;
};

const voucherTagContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  gap: "12px",
  marginTop: "10px",
  maxWidth: "100%",
};

const voucherTagStyle = {
  padding: "5px",
  display: "flex",
  gap: "10px",
  alignItems: "center",
  borderRadius: "8px",
  backgroundColor: "#e9ecef",
};

export const PromoCodeInput: React.FC<PromoCodeInputProps> = ({
  appliedVouchers,
  setAppliedVouchers,
  totalPrice,
}) => {
  const [currentDiscountCode, setCurrentDiscountCode] = useState("");
  const { t } = useTranslation();
  const { user } = useUserContext();
  const codeInputRef = useRef<HTMLInputElement>(null);

  const handleAddVoucher = () => {
    if (user.hasBeenReferred) {
      toast.error("You have already been Referred by someone");
    } else if (appliedVouchers.includes(currentDiscountCode)) {
      toast.error("Already added Voucher");
    } else if (user.referral && user.referral.code === currentDiscountCode) {
      toast.error("You cannot refer yourself");
    } else if (totalPrice * 0.15 < appliedVouchers.length * 15) {
      toast.error(
        "Unable to apply voucher, you have reached the maximum discount!"
      );
    } else {
            setAppliedVouchers((prevVouchers) => [...prevVouchers, currentDiscountCode]);
    }
    codeInputRef!.current!.value = "";
  };

  const handleRemoveVoucher = (voucher: string) => {
    setAppliedVouchers((oldValues) =>
      oldValues.filter((item) => item !== voucher)
    );
  };

  return (
    <>
      <Row xs={12} style={{ gap: "10px" }} className="pl-1">
        <Col xs={8} sm={6} md={4} lg={3} className="p-0">
          <Input
            innerRef={codeInputRef}
            id="voucher-code"
            name="voucher-code"
            className="input-field"
            placeholder={t("enter_discount_code")}
            style={{ height: "fit-content" }}
            onChange={(e) => setCurrentDiscountCode(e.target.value)}
            //disabled={appliedVouchers.length > 0}
          />
        </Col>
        <Col className="p-0">
          <Button
            color="primary"
            onClick={handleAddVoucher}
            //disabled={appliedVouchers.length > 0}
          >
            {" "}
            {t("apply")}{" "}
          </Button>
        </Col>
      </Row>
      <div style={voucherTagContainerStyle as React.CSSProperties}>
        {appliedVouchers.map((voucher, index) => {
          return (
            <div style={voucherTagStyle}>
              <FontAwesomeIcon color="grey" icon={faTag} />
              {voucher}
              <FontAwesomeIcon
                size="xs"
                color="grey"
                icon={faX}
                cursor="pointer"
                onClick={() => handleRemoveVoucher(voucher)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};
