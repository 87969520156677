import { useTranslation } from "react-i18next";
import WhatWhereSearch from "../search/WhatWhereSearch";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Header() {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [width, setWidth] = useState<number>(window.innerWidth);

  const [desktopTitle, setDesktopTitle] = useState("");
  const [mobileTitle, setMobileTitle] = useState("");

  const [desktopDescription, setDesktopDescription] = useState("");
  const [mobileDescription, setMobileDescription] = useState("");

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const bannerDesktopPath = process.env.REACT_APP_CMS_BANNER_PATH!;
    const absPath = cmsUrl + bannerDesktopPath;

    axios
      .get(absPath)
      .then((res) => {
        const {
          desktopImage,
          mobileImage,

          desktopTitle: _desktopTitle,
          desktopTitleColour,
          desktopDescription: _desktopDescription,
          desktopDescriptionColour,

          mobileTitle: _mobileTitle,
          mobileTitleColour,
          mobileDescription: _mobileDescription,
          mobileDescriptionColour,
        } = res.data.fields;

        const { src: desktopImageUrl } = desktopImage.fields.umbracoFile;
        const { src: mobileImageUrl } = mobileImage.fields.umbracoFile;

        setDesktopTitle(_desktopTitle);
        setMobileTitle(_mobileTitle);

        setDesktopDescription(_desktopDescription);
        setMobileDescription(_mobileDescription);

        document.documentElement.style.setProperty(
          "--banner-desktop-image",
          "url('/assets/img/frontpage/desktop_banner_for_sharing_economy.webp')"
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-image",
          "url('/assets/img/frontpage/mobile_banner_for_sharing_economy.webp')"
        );

        document.documentElement.style.setProperty(
          "--banner-desktop-title-color",
          "#" + desktopTitleColour
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-title-color",
          "#" + mobileTitleColour
        );

        document.documentElement.style.setProperty(
          "--banner-desktop-description-color",
          "#ffffff"
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-description-color",
          "#ffffff"
        );
      })
      .catch((err) => {
        document.documentElement.style.setProperty(
          "--banner-desktop-image",
          "/assets/img/about_us_bg.webp"
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-image",
          "/assets/img/about_us_bg.webp"
        );

        document.documentElement.style.setProperty(
          "--banner-desktop-title-color",
          "#ffffff"
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-title-color",
          "#ffffff"
        );

        document.documentElement.style.setProperty(
          "--banner-desktop-description-color",
          "#ffffff"
        );
        document.documentElement.style.setProperty(
          "--banner-mobile-description-color",
          "#ffffff"
        );
      });
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const routeChangeItemListing = () => {
    const path = "/item";
    navigate(path);
  };

  const isMobile = width <= 576;

  return (
    <section>
      <div className="hero">
        <div className="hero-content">
          <h1 className="mb-3">
            {t(`${isMobile ? mobileTitle : desktopTitle}`)}
          </h1>
          <h2 className="mb-4">
            {t(`${isMobile ? mobileDescription : desktopDescription}`)}
          </h2>
          <WhatWhereSearch homepage={true} searchTerm="" />
        </div>
      </div>
    </section>
  );
}