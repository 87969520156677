import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ItemDto, PricePeriod } from "../../api/rentMyApi";
import { getStandardPricePeriodPriceWithServiceFeeApplied } from "../../utils/pricePeriodUtils";
import { cleanUrl } from "../../utils/cleanUrl";

interface ItemHighlightOldProps {
  item: ItemDto;
  isFocused?: boolean;
  isItemSearch?: boolean;
  isAlreadyOnItemPage?: boolean;
}

export default function ItemHighlightOld({
  item,
  isFocused = false,
  isItemSearch = false,
  isAlreadyOnItemPage = false,
}: ItemHighlightOldProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const searchParams = new URLSearchParams(window.location.search);
  
  const itemImageURL = item.images && item.images[0] && item.images[0].compressedPath
    ? item.images[0].compressedPath
    : "/assets/img/placeholder.jpg";

  const startDateConst = "start-date";
  const endDateConst = "end-date";

  // const routeChange = () => {
  //   navigate(generateItemDetailedPath(), {
  //     state: isItemSearch ? { isFromSearch: true } : {},
  //   });
  // };

  const generateItemDetailedPath = () => {
    let path = `/item/${item.id}`;

    if (searchParams.get(startDateConst) && searchParams.get(endDateConst)) {
      path += `&${startDateConst}=${searchParams.get(
        startDateConst
      )}&${endDateConst}=${searchParams.get(endDateConst)}`;
    }

    return path;
  };

  const [standardPricePeriod, setStandardPricePeriod] = useState<PricePeriod>();

  useEffect(() => {
    if (!item) {
      return;
    }

    if (!item?.priceInfo?.periods) {
      return;
    }

    document.documentElement.style.setProperty(
      "--itemHighlightCursor",
      item.user.id ? "pointer" : "default"
    );

    setStandardPricePeriod(item.priceInfo.periods[0]);
  }, [item]);

  return (
    <>
      {item && (
        <div
          key={item.id}
          className={`item-highlight-container ${isFocused ? "focused" : ""}`}
        >
          <div className="d-flex justify-content-between">
            <div
              className="d-flex search_results_sections_header align-items-center clickable"
              onClick={() => navigate("/public/profile?id=" + item.user.id)}
            >
              <img
                width="100%"
                height="100%"
                alt="avatar of the user"
                src={
                    item.user.profileImage && item.user.profileImage.compressedPath
                        ? item.user.profileImage.compressedPath!
                        : "assets/img/profile-pic-placeholder.webp"
                }
                className="item-highlight-image"
              />

              <span>{item.user.firstName}</span>
            </div>
          </div>
          <img
            src={itemImageURL}
            onClick={() => window.open(`/item/${item.url}`, "_self")}
            className="listing-image object-cover position-center"
            alt={item.name}
          />
          <div className="d-flex align-items-center flex-wrap item-highlight-detail">
            <div className="mb-1">
              <h4 className="mb-0" onClick={() => window.open(`/item/${item.id}`, "_self")}>
                {item.name}
              </h4>
              <span>
                £{standardPricePeriod?.price?.daily?.toFixed(2)}/
                {t("search_results_price_day")}
              </span>
            </div>
            <div className="d-flex justify-content-between align-items-center">
            </div>
          </div>
        </div>
      )}
    </>
  );
}
