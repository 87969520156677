import * as React from "react";
import { ExploreTopCategories } from "../components/home/ExploreTopCategories";
import { OneWideImage } from "../components/home/OneWideImage";
import { TwoImages } from "../components/home/TwoImages";
import { LogoStrip } from "../components/home/LogoStrip";
import { Footer } from "../components/layout/Footer";
import Header from "../components/home/Header";

import { useUserContext } from "../context/UserContext";
import { useAuth0 } from "@auth0/auth0-react";

export function HomePage() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const { user } = useUserContext();
  const { isAuthenticated } = useAuth0();

  React.useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {/*{isMobile && isAuthenticated ? (*/}
      {/*  <MobileHomepage />*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    <Header />*/}
      {/*    <ExploreTopCategories />*/}
      {/*    <OneWideImage />*/}
      {/*    <TwoImages />*/}
      {/*    <LogoStrip />*/}
      {/*    <Footer />*/}
      {/*  </>*/}
      {/*)}*/}
      <>
        <Header />
        <ExploreTopCategories />
        <OneWideImage />
        <TwoImages />
        <LogoStrip />
        <Footer />
      </>
    </>
  );
}
