import React, { useEffect, useState } from "react";
import {
  IConfig,
  ItemClient,
  ItemDto,
  RentMyUserPublicDetailedDto,
  ReviewDetailedDto,
  UserClient,
} from "../../api/rentMyApi";
import Review from "./Review";

export type ReviewData = {
  review: ReviewDetailedDto;
  user: RentMyUserPublicDetailedDto;
};

export interface ReviewListProps {
  itemId: string;
  hidden: boolean;
  userId: string;
  showProduct?: boolean;
}

export const ReviewList: React.FC<ReviewListProps> = ({
  itemId = "",
  hidden = false,
  userId = "",
  showProduct = false,
}) => {
  const [itemClient] = useState<ItemClient>(
    new ItemClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [userClient] = useState<UserClient>(
    new UserClient(new IConfig("notoken"), process.env.REACT_APP_API_ENDPOINT)
  );
  const [reviewsData, setReviewsData] = useState<ReviewData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const pageSizeConst = 15;

  //for some reason isLoading would become false before the data is ready, delay stops this
  const useStateChangeBuffer = 750;

  const sortedReviewsData = [...reviewsData].sort((a, b) => {
    return (
      new Date(`${b.review.creation}`).getTime() -
      new Date(`${a.review.creation}`).getTime()
    );
  });

  useEffect(() => {
    async function initClient() {
      await populateAllReviews();
    }

    if (itemClient && userClient) {
      initClient();
    }
  }, [itemClient, userClient]);

  const populateAllReviews = async () => {
    if (itemId) {
      try {
        const response = await itemClient!.detailed27(itemId);
        if (!response.data) return;

        const reviewsDataPromises = response.data.map(async (review) => {
          try {
            const userResponse = await userClient!.detailed52(review.reviewer.id);
            return { review, user: userResponse } as ReviewData;
          } catch (error) {
            // console.error("Error retrieving user details", error);
            return null;
          }
        });

        const reviewsDataResults = await Promise.all(reviewsDataPromises);
        const validReviewsData = reviewsDataResults.filter(
          (data): data is ReviewData => data !== null
        );
        setReviewsData(validReviewsData);
      } catch (e) {
        // console.log("Error at populateAllReviews");
        // console.log(e);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const response = await itemClient.detailed25(
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          false,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          undefined,
          userId,
          undefined,
          undefined,
          pageSizeConst,
          1
        );
        if (!response.data) {
          return;
        }

        const reviewsDataPromises = response.data.map(async (item: ItemDto) => {
          try {
            const { data: review } = await itemClient!.detailed27(item.id);
            return Promise.all(
              review!.map(async (review) => {
                try {
                  const userResponse = await userClient!.detailed52(
                    review.reviewer.id
                  );
                  return { review, user: userResponse } as ReviewData;
                } catch (err) {
                  // console.error("Error retrieving user details", err);
                  return null;
                }
              })
            ).then((reviews) =>
              reviews.filter((review): review is ReviewData => review !== null)
            );
          } catch (error) {
            // console.error("Error retrieving user details", error);
            return [];
          }
        });

        const reviewsDataResults = (
          await Promise.all(reviewsDataPromises)
        ).flat();
        const validReviewsData = reviewsDataResults.filter(
          (data): data is ReviewData => data !== null
        );
        setReviewsData([...reviewsData, ...validReviewsData]);
      } catch (e) {
        // console.log("Error at populateAllReviews");
        // console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div className={hidden ? "hidden" : ""}>
      {isLoading && (
        <div className="loading-spinner-small text-align-center flex-important"></div>
      )}
      {sortedReviewsData.length > 0 &&
        sortedReviewsData.map(({ review, user }, index) => (
          <React.Fragment key={review.id}>
            <Review review={review} user={user} showProduct={showProduct} />
            {index < sortedReviewsData.length - 1 && <hr className={"short"} />}
          </React.Fragment>
        ))}
      {!sortedReviewsData.length && !isLoading && (
        <p className="auto-margin center-text font-grey">
          This user does not have any public reviews yet
        </p>
      )}
    </div>
  );
};
