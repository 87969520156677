import React from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UmbracoSiteLinks } from "./HeaderLinks";
import { useAuth0 } from "@auth0/auth0-react";
import { TopRightDropDownMenu } from "../../common/TopRightDropDownMenu";
import NavSearchBar from "./NavSearchBar";

interface NavbarDesktopProps {
  hamburgerMenuOpen: boolean;
  setHamburgerMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isSmallScreen: boolean;
}

const NavbarDesktop: React.FC<NavbarDesktopProps> = ({
  hamburgerMenuOpen,
  setHamburgerMenuOpen,
  isSmallScreen,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, logout, loginWithPopup } =
    useAuth0();
  const { pathname } = useLocation();

  const routeChangeItemListing = () => {
    navigate("/item-listing");
  };

  const login = () => {
    if (!isAuthenticated) {
      loginWithPopup();
      return;
    }
    // Delete the if statement above and uncomment the line below once uri gets whitelisted
    // loginWithRedirect({ redirectUri: window.location.origin + pathname });
  };

  const topRightDropDownMenu = TopRightDropDownMenu({
    isMobile: false,
    setHamburgerOpen: setHamburgerMenuOpen,
    isHamburgerOpen: hamburgerMenuOpen,
  });

  return (
    <div className="flexy container-fluid">
      <img
        className="navbar-image"
        src="/assets/img/RentMy-logo-no-tm.webp"
        onClick={() => navigate("/")}
        alt="RentMy Logo"
      />
      <div className="flexy center-item-align additional-padding-on-tablet">
        <NavSearchBar />
      </div>
      <div className="flexy flex-right center-item-align additional-padding-on-tablet">
        {(!isSmallScreen || pathname === "/") && (
          <div className="desktop-header-links-container" style={isAuthenticated ? {} : {marginRight: "32px"}}>
            {UmbracoSiteLinks.map((headerLinkObject, index) => {
              if (headerLinkObject.type === "link") {
                return (
                  <li key={index}>
                    <a
                      className="desktop-header-links text-align-center"
                      href={headerLinkObject.url}
                    >
                      {headerLinkObject.title}
                    </a>
                  </li>
                );
              } else if (headerLinkObject.type === "external-link") {
                return (
                  <li key={index}>
                    <a
                      className="desktop-header-links text-align-center"
                      href={headerLinkObject.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {headerLinkObject.title}
                    </a>
                  </li>
                );
              }
            })}
          </div>
        )}
        <div>
          <Button
            onClick={routeChangeItemListing}
            className="desktop-only navbar-button"
            color="primary"
            type="button"
          >
            {t("nav_create_listing")}
          </Button>
        </div>

        {!isAuthenticated && (
          <div>
            <Button
              onClick={login}
              className="desktop-only navbar-button"
              color="primary"
              type="button"
            >
              Login/Sign up
            </Button>
          </div>
        )}

        {isAuthenticated && (
          <div
            className={`navbar-item navmenu-dropdown ${
              isAuthenticated ? "block" : "hide"
            }`}
          >
            {topRightDropDownMenu}
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarDesktop;
