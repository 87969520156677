import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Container } from "reactstrap";

export function ExploreTopCategories() {
  const { t } = useTranslation();

  const [sectionBackgroundColor, setSectionBackgroundColor] = useState("");
  const [titleTextColor, setTitleTextColor] = useState("");

  // const [bodyTextColor, setBodyTextColor] = useState("");
  const [textTitle, setTextTitle] = useState("");
  const [imagePath1, setImagePath1] = useState("");
  const [imagePath2, setImagePath2] = useState("");
  const [imagePath3, setImagePath3] = useState("");
  const [imagePath4, setImagePath4] = useState("");
  const [imageCaption1, setImageCaption1] = useState("");
  const [imageCaption2, setImageCaption2] = useState("");
  const [imageCaption3, setImageCaption3] = useState("");
  const [imageCaption4, setImageCaption4] = useState("");
  const [imageAlt1, setImageAlt1] = useState("");
  const [imageUrl1, setImageUrl1] = useState("");
  const [imageUrl2, setImageUrl2] = useState("");
  const [imageUrl3, setImageUrl3] = useState("");
  const [imageUrl4, setImageUrl4] = useState("");

  useEffect(() => {
    const cmsUrl = process.env.REACT_APP_CMS_URL!;
    const fourImageBlockPath = process.env.REACT_APP_CMS_FOURIMAGE_PATH!;
    const absPath = cmsUrl + fourImageBlockPath;

    axios
      .get(absPath)
      .then((res) => {
        const { titleTextColor, sectionBackgroundColor } = res.data.fields;

        // setSectionBackgroundColor("#" + res.data.fields.sectionBackgroundColor);
        document.documentElement.style.setProperty(
          "--categories-background-color",
          "#" + sectionBackgroundColor
        );

        document.documentElement.style.setProperty(
          "--topCategoriesTitleTextColour",
          "#" + titleTextColor
        );

        // setBodyTextColor("#" + res.data.fields.bodyTextColor);
        setTextTitle(res.data.fields.title);

        setImagePath1("/assets/img/frontpage/category_img1.webp");
        setImagePath2("/assets/img/frontpage/category_img2.webp");
        setImagePath3("/assets/img/frontpage/category_img3.webp");
        setImagePath4("/assets/img/frontpage/category_img4.webp");
        setImageCaption1(res.data.fields.image1Caption);
        setImageCaption2(res.data.fields.image2Caption);
        setImageCaption3(res.data.fields.image3Caption);
        setImageCaption4(res.data.fields.image4Caption);
        setImageAlt1(res.data.fields.image1Alt);
        setImageUrl1(res.data.fields.image1Link);
        setImageUrl2(res.data.fields.image2Link);
        setImageUrl3(res.data.fields.image3Link);
        setImageUrl4(res.data.fields.image4Link);
      })
      .catch((err) => {});
  }, []);

  const routeChange = (url: string) => {
    // console.log(url);
    if (url !== "") {
      window.location.replace(url);
    }
  };

  return (
    <section className="four-image-block">
      <Container>
        <div className="col-12">
          <h3>{textTitle}</h3>
          <div className="row">
            <div className="col-6 col-md-3">
              <a
                href={imageUrl1}
                title=""
                onClick={() => routeChange(imageUrl1)}
              >
                <img src={imagePath1} alt="" />
                <h2 className="top-categories-text">{imageCaption1}</h2>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
                href={imageUrl2}
                title=""
                onClick={() => routeChange(imageUrl2)}
              >
                <img src={imagePath2} alt="" />
                <h2 className="top-categories-text">{imageCaption2}</h2>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
                href={imageUrl3}
                title=""
                onClick={() => routeChange(imageUrl3)}
              >
                <img src={imagePath3} alt="" />
                <h2 className="top-categories-text">{imageCaption3}</h2>
              </a>
            </div>
            <div className="col-6 col-md-3">
              <a
                href={imageUrl4}
                title=""
                onClick={() => routeChange(imageUrl4)}
              >
                <img src={imagePath4} alt="" />
                <h2 className="top-categories-text">{imageCaption4}</h2>
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
