import { ItemSimpleDto } from "../../api/rentMyApi";
import { useNavigate } from "react-router-dom";

const ItemEmbed = ({ item }: { item: ItemSimpleDto }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex justify-content-space-between align-items-center mb-1 clickable"
      onClick={() => navigate(`/item/${item.id}`)}
    >
      <p className="colour-black bold mb-0">{item.name}</p>

      <img
        src={
          item &&
          item.images &&
            item.images[0].compressedPath
        }
        alt="item-cover-image"
        style={{ maxHeight: "60px" }}
      />
    </div>
  );
};

export default ItemEmbed;
